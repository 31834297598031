import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import PlayIcon from 'assets/svg/play-arrow.svg';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { VideoModal } from 'components';

export const TastyTips = ({ tastyTipsData }: any) => {
  const [isModalOpen, setisModalOpen] = useState(false);
  const { title, tagline, image, video, contentImage } = tastyTipsData;
  const Image: any = getImage(image?.localFile);
  const ContentImage: any = getImage(contentImage?.localFile);
  const handleModalOpen = () => {
    setisModalOpen(true);
  };
  return (
    <div className="tasty-tips">
      <div className="tasty-tips-img-wrapper bg-pink">
        {Image && <GatsbyImage image={Image} alt={title || ''} />}
        {video && (
          <button
            type="button"
            className="videoplayer-action"
            onClick={() => {
              handleModalOpen();
            }}
          >
            <PlayIcon />
          </button>
        )}
      </div>
      <div className="tasty-tips-content bg-pink">
        <div className="tasty-tips-content-text-img-wrapper">
          {ContentImage && (
            <GatsbyImage image={ContentImage} alt={title || ''} />
          )}
        </div>

        <div className="tasty-tips-content-wrapper">
          {title && (
            <h3 className="tasty-tips-content-title text-lightgreen">
              {title}
            </h3>
          )}
          {tagline.data.tastyTipsTagline && (
            <div className="tasty-tips-content-tagline text-white">
              <ReactMarkdown>{tagline.data.tastyTipsTagline}</ReactMarkdown>
            </div>
          )}
        </div>
      </div>
      <VideoModal
        isModalOpen={isModalOpen}
        setisModalOpen={setisModalOpen}
        ModalPosterImage={image}
        ModalVideo={video}
      />
    </div>
  );
};
