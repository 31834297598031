/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC } from 'react';
import ReactModal from 'react-modal';

interface VideoModalProp {
  isModalOpen: any;
  setisModalOpen: any;
  ModalPosterImage: any;
  ModalVideo: any;
}
const VideoModal: FC<VideoModalProp> = ({
  isModalOpen,
  setisModalOpen,
  ModalPosterImage,
  ModalVideo,
}) => {
  const handleModalClose = () => {
    setisModalOpen(false);
  };
  return (
    <div>
      <ReactModal
        closeTimeoutMS={1000}
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        ariaHideApp={false}
        portalClassName="ReactModalPortal videoplayer-modal"
      >
        <button
          type="button"
          onClick={() => handleModalClose()}
          className="modal-close"
        >
          <span className="close">&#x2715;</span>
        </button>
        <div className="modal-img-container">
          <video
            preload="auto"
            autoPlay
            loop
            playsInline
            poster={ModalPosterImage.localFile.publicURL}
            controls
            className="video"
          >
            <source src={ModalVideo?.localFile.publicURL} type="video/mp4" />
          </video>
        </div>
      </ReactModal>
    </div>
  );
};
export { VideoModal };
