exports.components = {
  "component---src-domain-about-us-tsx": () => import("./../../../src/domain/about-us.tsx" /* webpackChunkName: "component---src-domain-about-us-tsx" */),
  "component---src-domain-blog-tsx": () => import("./../../../src/domain/blog.tsx" /* webpackChunkName: "component---src-domain-blog-tsx" */),
  "component---src-domain-contact-us-tsx": () => import("./../../../src/domain/contact-us.tsx" /* webpackChunkName: "component---src-domain-contact-us-tsx" */),
  "component---src-domain-forward-thinking-tsx": () => import("./../../../src/domain/forward-thinking.tsx" /* webpackChunkName: "component---src-domain-forward-thinking-tsx" */),
  "component---src-domain-our-product-range-tsx": () => import("./../../../src/domain/our-product-range.tsx" /* webpackChunkName: "component---src-domain-our-product-range-tsx" */),
  "component---src-domain-people-tsx": () => import("./../../../src/domain/people.tsx" /* webpackChunkName: "component---src-domain-people-tsx" */),
  "component---src-domain-quality-standards-tsx": () => import("./../../../src/domain/quality-standards.tsx" /* webpackChunkName: "component---src-domain-quality-standards-tsx" */),
  "component---src-domain-what-we-do-tsx": () => import("./../../../src/domain/what-we-do.tsx" /* webpackChunkName: "component---src-domain-what-we-do-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-details-tsx": () => import("./../../../src/templates/blog-details.tsx" /* webpackChunkName: "component---src-templates-blog-details-tsx" */),
  "component---src-templates-industry-tsx": () => import("./../../../src/templates/industry.tsx" /* webpackChunkName: "component---src-templates-industry-tsx" */),
  "component---src-templates-product-detail-tsx": () => import("./../../../src/templates/product-detail.tsx" /* webpackChunkName: "component---src-templates-product-detail-tsx" */)
}

