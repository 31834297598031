import React from 'react';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import Arrow from 'assets/svg/back.svg';

export const ProductDetails = ({ productDetails }: any) => {
  const {
    title,
    productDetailsTagline,
    description,
    nutritionalInformation,
    image,
  } = productDetails;
  const Image: any = getImage(image?.localFile);
  const backToParent = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    navigate(-1);
  };
  return (
    <section className="product-details-info">
      <div className="product-details-info-header">
        {title && (
          <h2 className="text-darkgreen font-body product-details-info-title">
            {title}
          </h2>
        )}
        <button
          className="product-details-info-parent"
          onClick={backToParent}
          type="button"
        >
          <Arrow /> Back
        </button>
      </div>
      <div className="product-details-info-intro font-xl text-pink">
        {productDetailsTagline && (
          <p className="product-details-info-tagline">
            {productDetailsTagline}
          </p>
        )}
        <div className="product-details-info-img">
          {Image && <GatsbyImage image={Image} alt={title || ''} />}
        </div>
      </div>
      <div className="product-details-info-content">
        {description && (
          <div className="product-details-info-content-description text-darkgreen">
            <ReactMarkdown className="htmlcontent">
              {description?.data?.description}
            </ReactMarkdown>{' '}
          </div>
        )}
        <div className="product-details-info-content-wrapper">
          <div className="product-details-info-content-nutrition">
            {nutritionalInformation.title && (
              <h3 className="card-title text-darkgreen">
                {nutritionalInformation.title}
              </h3>
            )}
            <div className="product-details-info-content-nutrition-value-wrapper">
              {nutritionalInformation?.nutritionValue?.map((value: any) => (
                <div className="product-details-info-content-nutrition-value">
                  <div className="product-details-info-content-nutrition-value-title">
                    {value.title}
                  </div>
                  <div className="product-details-info-content-nutrition-value-summary">
                    {value?.summary?.data?.summary}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="product-details-info-content-size">
            {varietyofSize.title && (
              <h3 className="card-title text-darkgreen">
                {varietyofSize.title}
              </h3>
            )}
            {varietyofSize.description && (
              <p className="text-darkgreen product-details-info-content-size-description">
                {varietyofSize?.description?.data?.description}
              </p>
            )}
            <div className="product-details-info-content-size-wrapper">
              <Arrow className="product-details-info-content-size-arrow" />
              {varietyofSize?.size?.map((value: any) => (
                <p className="product-details-info-content-size-item">
                  {value.title}
                </p>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};
