import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link, graphql, useStaticQuery } from 'gatsby';

const IdealIndustries = ({ idealIndustriesdata }: any) => {
  const industrySlug = useStaticQuery(graphql`
    {
      strapiWhatWeDo {
        slug
      }
    }
  `);
  return (
    <div className="container">
      <div className="imagetags-wrapper">
        <h2 className="imagetags-heading text-green">
          {idealIndustriesdata.title}
        </h2>
        <div className="imagetags-section">
          {idealIndustriesdata?.IdealIndustries?.map((item: any) => {
            const { title, industry } = item;
            if (!industry) {
              return null;
            }
            const { image } = industry;
            return (
              <div className="imagetags-detail">
                <div className="imagetags-img industry">
                  {image?.localFile?.childImageSharp?.gatsbyImageData && (
                    <GatsbyImage
                      image={image?.localFile?.childImageSharp?.gatsbyImageData}
                      alt={title || ''}
                    />
                  )}
                </div>
                <div className="imagetags-tag_title">{title}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export { IdealIndustries };
