/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import ReadMore from 'assets/svg/read-more-arrow.svg';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ModalComponent, ProductListDetails, ContactForm } from 'components';

const ProductList = ({
  data,
  title: sectionTitle,
  // setisModal,
  closeModal,
  isModal,
  selectedProduct,
  // setSelectedProduct,
  parentSlug,
}: // brochureUrl,
any) => {
  // const handler = (item: any) => {
  //   setSelectedProduct(item);
  //   setisModal(true);
  //   window.history.pushState('', '', `/${parentSlug}/${item.slug}`);
  // };
  return (
    <div className="product-list-container">
      {sectionTitle && <h2 className="product-list-Heading">{sectionTitle}</h2>}
      <ul className="product-list-wrapper">
        {data &&
          data.length &&
          data.length > 0 &&
          data
            .sort((a: any, b: any) => a.title.localeCompare(b.title))
            .map((item: any) => {
              const { id, title, image, color } = item;
              return (
                <li key={id} className="product-list-item">
                  <div className="product-list-item-wrapper">
                    <a
                      href={`/${parentSlug}/${item.slug}`}
                      className="product-list-item-img"
                    >
                      {image?.localFile?.childImageSharp?.gatsbyImageData && (
                        <GatsbyImage
                          image={
                            image?.localFile?.childImageSharp?.gatsbyImageData
                          }
                          alt={title}
                        />
                      )}
                    </a>
                    <div className="product-list-item-details">
                      <h5 className="product-list-item-title">
                        {title} {color && <span>({color})</span>}
                      </h5>
                      <div className="product-list-item-cta">
                        <a href={`/${parentSlug}/${item.slug}`}>
                          See more <ReadMore />
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
      </ul>
      {/* <div className="product-list-download">
        <a href={brochureUrl} download="Product Catalogue">
          Download Product Catalogue
        </a>
      </div> */}
      <ModalComponent
        isModal={isModal}
        closeModal={closeModal}
        className="product-list-modal"
      >
        <div className="product-details-item">
          <ProductListDetails
            closeModal={closeModal}
            ProductInfo={selectedProduct}
          />
          <div className="product-enquiry-form">
            <div className="product-enquiry-title">
              <h3>Send Product Enquiry</h3>
            </div>
            <ContactForm formType="product" />
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export { ProductList };
