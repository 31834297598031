import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BlogCard } from 'components/BlogCard';
import { CTALink } from 'components';
import Arrow from 'assets/svg/readarrow.svg';

const ProductBlogSlider = ({ data }: any) => {
  const sliderList = useStaticQuery(graphql`
    {
      strapiBlogPage {
        slug
      }
    }
  `);
  return (
    <div className="blog-slider-wrapper">
      <div className="container productPage">
        <div className="productPage-head">
          <div className="blog-slider-heading">
            {data && data.title && <h2>{data.title}</h2>}
          </div>
          {data && data.description && (
            <div className="blog-slider-description">
              <p>{data.description?.data?.description}</p>
            </div>
          )}
        </div>
      </div>

      <div className="blog-slider">
        <Swiper
          slidesPerView={1.15}
          spaceBetween={20}
          breakpoints={{
            592: {
              slidesPerView: 2,
              spaceBetween: 15,
              centeredSlides: false,
            },
            1024: {
              slidesPerView: 2.5,
              spaceBetween: 18,
              centeredSlides: false,
            },
            1400: {
              slidesPerView: 3.5,
              spaceBetween: 25,
              centeredSlides: false,
            },
            2000: {
              slidesPerView: 5,
              spaceBetween: 30,
              centeredSlides: false,
            },
          }}
        >
          {data.articles &&
            data.articles.map((item: any) => {
              console.log(item);
              return (
                <SwiperSlide key={item.title}>
                  <BlogCard
                    item={item}
                    parentUrl={sliderList.strapiBlogPage.slug}
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};

export { ProductBlogSlider };
