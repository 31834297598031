/* eslint-disable no-plusplus */
import React, { useRef, useEffect, useState } from 'react';

export const ProductAvailability = ({ data, labels }: any) => {
  const canvasChartRef: any = useRef(null);
  const [monthsSegmentWidth, setMonthSegmentWidth]: any = useState(null);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const getColorsFromText = (text: string) => {
    switch (text) {
      case 'pink':
        return ['#AC0755', '#460323'];
      case 'orange':
        return ['#FF6B00', '#994000'];
      case 'green':
        return ['#BAE360', '#667D35'];
      default:
        return ['#AC0755', '#460323'];
    }
  };
  useEffect(() => {
    const canvasChart = canvasChartRef.current;
    const ctx = canvasChart.getContext('2d');

    const drawChart = () => {
      const width = canvasChart.clientWidth;
      const height = canvasChart.clientHeight;
      canvasChart.width = width;
      canvasChart.height = height;

      const totalSegments = 12;
      const segmentWidth = width / totalSegments;
      setMonthSegmentWidth(segmentWidth - 1);
      ctx.clearRect(0, 0, width, height); // Clear the canvas before drawing

      for (let i = 0; i < data.ProductAvailability.length; i++) {
        const filteredLable = labels.filter(
          (label: any) => label.type === data.ProductAvailability[i].type
        );
        const filteredColor = getColorsFromText(filteredLable[0].color);
        const gradient = ctx.createLinearGradient(0, 0, 0, height);
        gradient.addColorStop(0, filteredColor[0]);
        gradient.addColorStop(1, filteredColor[1]);
        ctx.fillStyle = gradient;

        let currentSum = 0;
        for (let j = 0; j < i; j++) {
          currentSum += data.ProductAvailability[j].portion;
        }
        if (i === 0) {
          ctx.fillRect(
            0,
            0,
            data.ProductAvailability[i].portion * segmentWidth,
            height
          );
        } else {
          ctx.fillRect(
            currentSum * segmentWidth,
            0,
            data.ProductAvailability[i].portion * segmentWidth,
            height
          );
        }
      }
    };
    drawChart();
    window.addEventListener('resize', drawChart);
    return () => {
      window.removeEventListener('resize', drawChart);
    };
  }, []);
  return (
    <div className="product-availability">
      <div className="product-availability-header">
        <h3 className="product-availability-title">{data.title}</h3>
        <div className="product-availability-desktop-labels">
          {labels.map((label: any) => {
            return (
              <div
                className="product-availability-desktop-label"
                key={label.title}
              >
                <i
                  className={`product-availability-desktop-label-color  ${label.color}`}
                />
                <div className="product-availability-desktop-label-content">
                  <p className="product-availability-desktop-label-title">
                    {label.title}
                  </p>
                  <p className="product-availability-desktop-label-description">
                    {label.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="product-availability-chart">
        <canvas
          ref={canvasChartRef}
          style={{
            width: '100%',
            height: '30px',
            border: '4px solid white',
            borderRadius: '20px',
            boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
          }}
        />
        <div className="product-availability-chart-month">
          {months.map(month => {
            return <span style={{ width: monthsSegmentWidth }}>{month}</span>;
          })}
        </div>
      </div>
      <div className="product-availability-mobile-labels">
        {labels.map((label: any) => {
          return (
            <div
              className="product-availability-mobile-label"
              key={label.title}
            >
              <i
                className={`product-availability-mobile-label-color ${label.color}`}
              />
              <p className="product-availability-mobile-label-title">
                {label.title}
              </p>
              <p className="product-availability-mobile-label-description">
                {label.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
